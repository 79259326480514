<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"

    export let entry: FormEntry
</script>


<div {...entry.rowAttr} class="hidden">
    <input
            {...entry.attr}
            autocomplete="off"
            tabindex="-1"
            disabled={entry.disabled ? 'disabled' : null}
            id={entry.id}
            name={entry.fullName}
            on:input={e => entry.value = e.target.value}
            on:input
            required={entry.required ? 'required' : null}
            type="hidden"
            value={entry.value}
    />
</div>
